<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27">
    <defs>
      <linearGradient id="p86t2p1jsa" x1="100%" x2="0%" y1="97.426%" y2="2.574%">
        <stop offset="0%" stop-color="#FFA300" />
        <stop offset="100%" stop-color="#F06E1B" />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g fill="url(#p86t2p1jsa)" fill-rule="nonzero" transform="translate(-120 -1123)">
        <g>
          <path
            d="M1.63 16.931c-.895.968-.845 2.475.113 3.38l3.56 3.365c1.606 1.517 4.138 1.446 5.655-.16.077-.081.151-.166.221-.254L25.744 4.987c.817-1.025.65-2.518-.373-3.338-1.02-.816-2.508-.651-3.324.368l-.005.006-14.05 17.659L4.97 16.82c-.942-.893-2.43-.852-3.322.09l-.019.02z"
            transform="translate(120 1123)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
